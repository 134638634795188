<template>
  <component
    v-if="shippingAreaAsyncComponent"
    :is="shippingAreaAsyncComponent"
    :is-draft-mode="isDraftMode"
    :product-to-add="productToAdd"
    :available-filter="availableFilter"
    :open-tab="openTab"
    @close="$emit('close', $event)"
    @reload="loadComponent()"
  />
</template>
<script>
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import ALoadingError from 'theme/components/atoms/a-loading-error.vue';

export default {
  name: 'ShippingAreaAsync',
  props: {
    isDraftMode: {
      type: Boolean,
      default: () => false
    },
    openTab: {
      type: [String, null],
      default: () => null
    },
    availableFilter: {
      type: [String, null],
      default: () => null
    },
    productToAdd: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      shippingAreaAsyncComponent: null
    }
  },
  methods: {
    loadComponent () {
      this.shippingAreaAsyncComponent = () => ({
        component: import(/* webpackChunkName: "vsf-shipping" */ '$modules/shipping/components/shipping-area.vue'),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 30000
      })
    }
  },
  mounted () {
    this.loadComponent()
  }
}
</script>

import { render, staticRenderFns } from "./shipping-area-async.vue?vue&type=template&id=38a66186"
import script from "./shipping-area-async.vue?vue&type=script&lang=js"
export * from "./shipping-area-async.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports